<template>
  <div id="Gateway">
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" center>
      <span>请先登录再做题</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 登录框 -->

    <div class="header">
      <h1>西语题库 全新上线 永久免费</h1>
      <p>
        西语人的专属训练营，权威解析，辅助提高您的答题技巧与知识水平，高效学习
      </p>
      <div>
        <button @click="testlist">全部试题列表</button>
        <button class="show" v-if="login" @click="logintest">登录做题</button>
        <!-- <button class="show" v-if="personal">个人中心</button> -->
      </div>
    </div>
    <div class="main">
      <h2>热门试题·考试必备</h2>
      <p>大家都在练习，快来免费答题</p>
      <div class="contant">
        <div
          class="main-content"
          v-for="(item, index) in indexList"
          :key="index"
          @click="clickLinkTestinfo(item)"
        >
          <div class="count">
            <img src="@/assets/image/listlogo.png" alt="" />
            <h3>{{ item.name }}<span class="recommend">推荐</span></h3>
            <div class="stration">
              <div class="peplo">
                <img src="@/assets/image/yonghuming.png" alt="" /><span>{{
                  item.records_count
                }}</span>
              </div>
              <div class="diffcult">
                <span class="demonstration">难度：</span>
                <el-rate v-model="item.difficultys" disabled></el-rate>
              </div>
            </div>
          </div>
          <!-- <div class="time">
            <p>
              <img src="@/assets/image/shijian.png" alt="" /><span>{{item.durations}}h</span>
             
            </p>

            <p v-show="maketext">去做题</p>
          </div> -->
        </div>
      </div>
      <div class="showmore" @click="testlist">
        查看更多<i class="el-icon-right"></i>
      </div>
    </div>

    <div class="bottom">
      <div class="bottom-count">
        <div class="advantage">
          <h2>西语题库优势</h2>
          <p>西语题型量身定制，最适合的答题系统，西语人必备！</p>
        </div>
        <div class="bottom-text">
          <div v-for="item in bottomlist" :key="item.id">
            <img :src="item.img" alt="" />
            <h2>{{ item.title }}</h2>
            <p>{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <LoginBox ref="loginBox"></LoginBox>
  </div>
</template>

<script>
import axios from "axios";
//例如：import 《组件名称》 from '《组件路径》';
import LoginBox from "@/components/LoginBox";
export default {
  components: {
    LoginBox,
  },
  head: {
    title: "西知题库 · 永久免费 · 西语人的专属训练营 - 西知题库",
  },
  inject: ["reload"],
  data() {
    return {
      difficultys: "",
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      time: "",
      name: "",
      boolean: false,
      dialogVisible: false, //弹框
      // mainList:[]//试题列表
      bottomlist: [
        {
          id: 1,
          title: "真实考试场景",
          text: "模拟考试场景，反复训练，强化答题技巧",
          img: require("../../assets/image/test.png"),
        },
        {
          id: 2,
          title: "一站式学习+自测",
          text: "从基础到进阶，由浅入深易上手",
          img: require("../../assets/image/jichu.png"),
        },
        {
          id: 3,
          title: "随时随地答题",
          text: "手机/电脑双端，不受限制，体验触手可及",
          img: require("../../assets/image/asideanswer.png"),
        },
      ],
      login: false,
      personal: false,
      indexList: [], //试卷列表
    };
  },
  created() {
    let _this = this;
    if (localStorage.userInfo == undefined) {
      this.login = true;
      // this.boolean = true;
    } else {
      this.personal = true;
    }
    let obj = {
      limit: 6,
      page: 1,
      order: _this.records_count,
      sort: _this.desc,
    };
    _this.$api.indexlist(obj).then((res) => {
      console.log(res);
      _this.indexList = res.data.lists.data;
    });
     _this.browserRedirect();
  },
  methods: {
    clickLinkTestinfo(val) {
      // 跳转详情
      let _this = this;
      // _this.$router.push({
      //   query: { id: val.id },
      //   path: "/Index/Testinfo",
      // });

      const { href } = this.$router.resolve({
        path: "/Index/Testinfo",
        query: {
          id: val.id,
        },
      });
      window.open(href, "_blank");
    },

    testlist() {
      // if (localStorage.userInfo == undefined) {
      //   // 登录框
      //   let _this = this;
      //   _this.loginDialogBool = true;
      //   _this.weixin_login();
      // } else {
      this.$router.push("/Index/Home");
      // }
    },
    logintest() {
      // 登录框
      let _this = this;
      _this.$refs.loginBox.clickloginDialogBool();
    },

    showMore() {
      if (localStorage.userInfo == undefined) {
        this.dialogVisible = true;
      } else {
        this.$router.push("/Index/Home");
      }
    },
    browserRedirect() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      if (
        /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(
          sUserAgent
        )
      ) {
        //跳转移动端页面
        console.log("移动端页面");
        window.location.href = "http://m.tk.spanishknow.com/#/Testsort";
        // window.location.href = "http://192.168.1.103:3333/#/Testsort";
      } else {
        //跳转pc端页面
        console.log("pc端页面");
      }
    },
  },

};
</script>

<style lang="less" scoped>
// @bgcolor: #c7000b;
@bgcolor: #df3833;
@redcolor: #df3833;
#Gateway {
  /deep/.el-dialog__wrapper {
    .el-dialog--center {
      .el-dialog__body {
        text-align: center;
      }
    }
  }
  .login {
    // 登录框
    /deep/ .el-dialog {
      // height: 480px;
      border-radius: 8px;
      // /deep/.el-carousel__container{
      //   height: 400px;
      // }
      .showBtn {
        height: 400px;
        .logo {
          text-align: center;
          margin-bottom: 40px;
          img {
            width: 60px;
            height: 60px;
            margin-bottom: 20px;
          }
          div {
            font-size: 20px;
          }
        }
      }
      .avaterBox {
        .van-uploader {
          position: relative;
          z-index: 9999;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: 25px;
          img {
            width: 80px;
            height: 80px;
          }
          /deep/.van-uploader__input {
            z-index: 9999;
          }
          button {
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 30px;
            font-size: 12px;
            background-color: rgba(0, 0, 0, 0.5);
            bottom: -18px;
            border-radius: 0;
            color: #fff;
          }
        }
        .avatar-uploader {
          width: 80px;
          height: 80px;
          margin: 0 auto;
          position: relative;
          border-radius: 50%;
          overflow: hidden;
          button {
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 26px;
            line-height: 26px;
            font-size: 12px;
            background-color: rgba(0, 0, 0, 0.5);
            bottom: 0px;
            padding: 0;
            border-radius: 0;
            color: #fff;
            left: 0;
            margin: 0;
          }
        }

        i {
          font-size: 18px;
        }
        .msg {
          text-align: center;
          h2 {
            height: 30px;
            font-size: 22px;

            color: #333;
            line-height: 30px;
            margin: 0;
          }
          p {
            height: 22px;
            font-size: 16px;

            color: #999;
            line-height: 22px;
            margin: 6px 0 30px;
          }
        }
        .username {
          width: 100%;
          height: 44px;
          border: 1px solid #ccc;
          border-radius: 20px;
          margin: 20px 0px 0px;
          display: flex;
          box-sizing: border-box;
          > div {
            &:first-child {
              display: flex;
              align-items: center;
              margin-left: 20px;

              img {
                width: 18px;
                margin-right: 5px;
              }
            }
          }
          /deep/.el-input {
            width: 200px;
            height: 44px !important;
            border: none;
            .el-input__inner {
              border: none;
              height: 42px;
              padding-top: 2px;
            }
          }
        }
        button {
          font-size: 14px;
          box-sizing: border-box;
          border-radius: 40px;
          width: 100%;
          margin-left: 2px;
          border-radius: 40px;
          margin-bottom: 20px;
          height: 44px;
          margin-top: 20px;
          // box-shadow: 0 2px 8px 0 rgba(47, 90, 255, 0.35);
        }
        .el-button--primary.is-disabled,
        .el-button--primary.is-disabled:active,
        .el-button--primary.is-disabled:focus,
        .el-button--primary.is-disabled:hover {
          background: rgba(199, 0, 11, 0.3);
          border: rgba(199, 0, 11, 0.3);
        }
        .el-button--primary {
          background: @bgcolor;
          border: @bgcolor;
        }
      }
      .title {
        // width: 20px;
        height: 20px;
        background: url(../../assets/image/left.png) 0 0 no-repeat;
        background-size: 20px 20px;
        position: absolute;
        top: -0px;
        left: 20px;
        cursor: pointer;
        padding-left: 20px;
      }

      .btn {
        margin-left: 5px;
        button {
          width: 320px;
          border-radius: 40px;
          margin-bottom: 20px;
          height: 50px;
          background: @bgcolor;
          border: @bgcolor;
          i {
            margin-right: 10px;
            width: 30px;
            height: 30px;
            display: inline-block;
            margin-top: -3px;
            vertical-align: middle;
            background: url(../../assets/image/weixin.svg);
            background-size: 30px 30px;
            background-repeat: no-repeat;
            background-position: 0 0;
          }
        }
        > div {
          &:nth-child(2) {
            button {
              color: #000;
              background: #fbe7e7;
              border: none;
            }
          }
        }
      }
      .agreement {
        padding: 0 15px 20px 15px;
        font-size: 12px;
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background: #df3833;
          border: #df3833;
        }
        input {
          vertical-align: sub;
        }
        span {
          color: #666;
          a {
            color: #df3833;
            cursor: pointer;
          }
        }
      }
      .weixinBox {
        > div {
          padding: 60px 0;
          padding-bottom: 20px;
          text-align: center;
          font-size: 18px;
        }
        /deep/ iframe {
          width: 200px;
          height: 200px;
        }
        .modeShua {
          position: absolute;
          left: 74px;
          z-index: 200;
          top: 76px;
          background: rgba(0, 0, 0, 0.5);
          width: 181px;
          height: 181px;
          padding-top: 20px;
          color: #fff;
          padding-top: 50px;
          div {
            margin-bottom: 10px;
          }
          /deep/button {
            width: 50px;
            height: 30px;
            border-radius: 5px;
            &:hover {
              color: @bgcolor !important;
            }
          }
        }
      }
      .phoneBox {
        text-align: center;
        .phone {
          border-radius: 40px;
          width: 320px;
          margin: 20px 0;
          height: 42px;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          font-size: 14px;
          padding-left: 18px;
          margin-left: 4px;
          input {
            font-size: 14px;
            box-sizing: border-box;
            color: #333;
            margin: 20px 0;
            height: 100%;
            padding-left: 10px;
            border: none;
          }
        }
        .weixin {
          cursor: pointer;
          &:hover {
            color: @bgcolor;
          }
        }

        button {
          font-size: 14px;
          box-sizing: border-box;
          border-radius: 40px;
          width: 320px;
          border-radius: 40px;
          margin-bottom: 20px;
          height: 50px;
          // box-shadow: 0 2px 8px 0 @bgcolor;
        }
        .el-button--primary.is-disabled,
        .el-button--primary.is-disabled:active,
        .el-button--primary.is-disabled:focus,
        .el-button--primary.is-disabled:hover {
          background: rgba(199, 0, 11, 0.3);
          border: rgba(199, 0, 11, 0.3);
        }
        .el-button--primary {
          background: @bgcolor;
          border: @bgcolor;
        }
      }
      .codeBox {
        text-align: center;
        .code_bg {
          width: 300px;
          height: 150px;
          background: url(../../assets/image/code.svg) no-repeat 85px;
        }
        > div {
          span {
            color: @bgcolor;
          }
        }
        input {
          border-radius: 40px;
          width: 300px;
          margin: 20px 0;
          height: 42px;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          font-size: 14px;
          padding-left: 40px;
          margin-left: 15px;
          background: url(../../assets/image/codeD.png) no-repeat 10px 10px;
          background-size: 20px 20px;
        }

        button {
          font-size: 14px;
          box-sizing: border-box;
          border-radius: 40px;
          width: 300px;
          margin-left: 2px;
          border-radius: 40px;
          margin-bottom: 20px;
          height: 50px;
          // box-shadow: 0 2px 8px 0 rgba(47, 90, 255, 0.35);
        }
        .el-button--primary.is-disabled,
        .el-button--primary.is-disabled:active,
        .el-button--primary.is-disabled:focus,
        .el-button--primary.is-disabled:hover {
          background: rgba(199, 0, 11, 0.3);
          border: rgba(199, 0, 11, 0.3);
        }
        .el-button--primary {
          background: @bgcolor;
          border: @bgcolor;
        }
      }
    }
  }
  width: 100%;
  height: 100%;
  background: #fff;
  .header {
    width: 100%;
    background: url("../../assets/image/banner.png") center -100px no-repeat;
    height: 650px;
    padding-top: 200px;

    h1 {
      text-align: center;
      box-sizing: border-box;

      line-height: 55px;
      font-size: 48px;
      color: rgba(55, 84, 239);
      font-weight: 400;
    }
    p {
      font-size: 18px;
      color: #252b3a;
      line-height: 22px;
      margin-top: 20px;
      text-align: center;
    }
    div {
      text-align: center;

      button {
        width: 164px;
        height: 40px;
        line-height: 40px;

        border-radius: 2px;
        font-size: 14px;
        &:hover {
          cursor: pointer;
          box-shadow: 0px 4px 15px @bgcolor;
        }

        margin: 40px 6px;
        text-align: center;
        &:first-child {
          background: @bgcolor;
          color: #fff;
          border: @bgcolor;
        }
      }
      .show {
        border: 1px solid @bgcolor;
        background: none;
        color: @bgcolor;
      }
    }
  }
  .main {
    width: 1180px;
    margin: auto;
    padding-top: 50px;

    h2 {
      text-align: center;
      line-height: 46px;
      font-size: 36px;
      font-weight: inherit;
      color: #252b3a;
    }

    p {
      text-align: center;
      line-height: 20px;
      margin: 10px 0 40px 0;
      font-size: 14px;
      color: #575d6c;
    }
    .contant {
      display: flex;
      flex-wrap: wrap;
      .main-content {
        width: 372px;
        height: 240px;

        padding: 10px 30px 20px 30px;
        margin-right: 20px;
        margin-bottom: 20px;
        background: #fff;
        border: 1px solid #dfe1e6;
        border-radius: 2px;
        font-size: 14px;
        color: #575d6c;
        text-align: center;
        vertical-align: top;

        &:hover {
          cursor: pointer;
          box-shadow: 0px 4px 15px #cfcfcf;
          border: none;
        }
        .count {
          margin: 0 auto;
          padding-top: 10px;

          img {
            max-width: 50px;
            max-height: 65px;
          }
          h3 {
            margin: 15px 0 20px 0;
            height: 48px;
            font-size: 16px;
            font-weight: 500;
            color: #575d6c;
            text-align: center;
            span {
              background: @bgcolor;
              color: #fff;
              font-size: 8px;
              padding: 2px 4px;
              margin: 0 6px;
            }
          }
          .stration {
            margin-bottom: 15px;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            // width: 160px;
            .peplo {
              display: flex;
              align-items: center;
              img {
                width: 14px;
                height: 14px;
                position: relative;
                margin-left: 5px;
                // top: 2px;
              }
            }
            .diffcult {
              display: flex;
              /deep/.el-rate__icon {
                margin-right: 0px;
              }
            }
          }
        }
        .time {
          display: flex;
          height: 40px;

          line-height: 20px;
          margin-top: 10px;

          p {
            display: flex;
            img {
            }
            span {
              margin-right: 16px;
              margin-left: 4px;
              color: #575d6c;
              font-size: 14px;
            }
            &:last-child {
              font-size: 16px;
              margin-left: 120px;
              color: @bgcolor;
            }
          }
        }
      }
    }
    .showmore {
      margin: 20px 0;
      font-size: 16px;
      color: #526ecc;
      text-align: center;
      cursor: pointer;
    }
  }
  .bottom {
    margin: 0 auto;
    margin-top: 0;
    padding-bottom: 10px;
    background-color: #ecf8ff;
    padding-top: 50px;
    box-sizing: border-box;
    background: #edf2ff;
    position: relative;
    z-index: 1;
    .bottom-count {
      margin: 0 auto;
      width: 1180px;
      .advantage {
        text-align: center;
        margin-bottom: 40px;
        h2 {
          line-height: 46px;
          font-size: 36px;
          font-weight: inherit;
          color: #252b3a;
        }
        p {
          line-height: 20px;
          margin-top: 10px;
          font-size: 14px;
          color: #575d6c;
        }
      }
      .bottom-text {
        display: flex;

        justify-content: space-between;
        div {
          width: 310px;
          height: 156px;

          margin-bottom: 50px;
          box-sizing: border-box;
          text-align: center;
          h2 {
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 18px;
            line-height: 22px;
            font-weight: inherit;
            color: #575d6c;
            opacity: 1;
          }
          p {
            font-size: 14px;
            color: #575d6c;
            line-height: 22px;
            opacity: 0.6;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>